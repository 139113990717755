'use client'

import cn from 'classnames'
import NavLogoSection from '../NavLogoSection'
import AvatarCircle from '@crystal-eyes/clear-components/Avatar/AvatarCircle'
import useMeV2 from '@crystal-eyes/hooks/user/useMeV2'
import { getFullUrl, routes } from '@crystal-eyes/lib/routes'

interface TopNavigationProps {
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  mobileOpen: boolean
  setMobileOpen: (mobileOpen: boolean) => void
}

export default function TopNavigation({
  expanded,
  setExpanded,
  mobileOpen,
  setMobileOpen,
}: TopNavigationProps) {
  const { data: me } = useMeV2()
  const photoUrl = me?.identity?.personalInfo?.photoUrl
  const name = me?.identity?.personalInfo?.fullName
  const discType = me?.identity?.snapshot?.personality?.discType

  return (
    <nav
      className={cn(
        'tw-w-full',
        'tw-h-[72px]',
        'tw-flex',
        'tw-items-center',
        'tw-bg-secondary',
        'tw-pr-6',
        'tw-justify-between',
        'tw-border-b',
        'tw-border-dotted',
        'tw-border-secondary',
        'lg:tw-pr-16',
        'tw-fixed',
        'tw-top-0',
        'tw-z-[10000]',
        'tw-left-0',
        'tw-right-0',
      )}
    >
      <NavLogoSection
        expanded={expanded}
        setExpanded={setExpanded}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
      />

      <a
        href={getFullUrl(routes.settings, 'classic')}
        className={cn('tw-flex', 'tw-items-center', 'tw-pl-6')}
      >
        <AvatarCircle
          r={20}
          name={name}
          discType={discType}
          photoUrl={photoUrl}
        />
      </a>
    </nav>
  )
}
