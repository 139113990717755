'use client'

import cn from 'classnames'
import { useState } from 'react'
import { usePathname } from 'next/navigation'
import SideNavigation from '@crystal-eyes/clear-components/Navigation/SideNavigation/SideNavigation'
import TopNavigation from '@crystal-eyes/clear-components/Navigation/TopNavigation/TopNavigation'

interface OwnProps {
  children: React.ReactNode
}

export default function MainContent({ children }: OwnProps) {
  const pathName = usePathname()
  const [mobileOpen, setMobileOpen] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<boolean>(true)

  if (pathName.includes('/login')) {
    return (
      <>
        <div className={cn('tw-flex', 'tw-flex-col', 'tw-w-full')}>
          {children}
        </div>
      </>
    )
  }

  return (
    <>
      <TopNavigation
        expanded={expanded}
        setExpanded={setExpanded}
        mobileOpen={mobileOpen}
        setMobileOpen={setMobileOpen}
      />
      <div className={cn('tw-flex', 'tw-pt-[72px]')}>
        <SideNavigation
          expanded={expanded}
          setExpanded={setExpanded}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          appName={'meetings'}
        />
        <div className={cn('tw-flex tw-flex-col tw-w-full')}>{children}</div>
      </div>
    </>
  )
}
