'use client'

import Image from 'next/image'
import { BiChevronsLeft } from 'react-icons/bi'
import Button from '@crystal-eyes/clear-components/Buttons/Button'
import cn from 'classnames'

export default function NavLogoSection({
  expanded,
  setExpanded,
  mobileOpen,
  setMobileOpen,
}: {
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  mobileOpen: boolean
  setMobileOpen: (mobileOpen: boolean) => void
}) {
  const handleLogoClick = () => {
    if (window.innerWidth < 1024) {
      setMobileOpen(!mobileOpen)
    } else {
      setExpanded(!expanded)
    }
  }
  return (
    <div
      className={cn(
        'tw-flex',
        'tw-flex-row',
        'tw-items-center',
        'tw-p-4',
        'lg:tw-border-r',
        'lg:tw-border-dotted',
        'lg:tw-border-secondary',
        'tw-pl-6',
        'tw-h-full',
        'tw-z-[100]',
        expanded ? 'tw-justify-between' : 'tw-justify-center',
        expanded ? 'tw-w-[200px]' : 'tw-w-[72px]',
        'lg:tw-pl-4',
      )}
    >
      <Button theme="only-text" onClick={handleLogoClick}>
        <Image
          src="/static/share/images/logo/crystal-logo-2025.svg"
          alt="crystal logo"
          width={32}
          height={32}
        />
      </Button>
      {expanded && (
        <Button
          theme="only-text"
          onClick={() => setExpanded(!expanded)}
          className="lg:tw-flex tw-hidden"
        >
          <BiChevronsLeft className={cn('tw-h-6', 'tw-w-6')} />
        </Button>
      )}
    </div>
  )
}
