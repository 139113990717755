export const publicPaths = [
  '/api/.*',
  '/login',
  '/logout',
  '/okta',
  '/why-crystal/.*',
  '/tos',
  '/sign-up',
  '/404',
  '/_error',
  '/new-assessment',
  '/onboarding',
  '/password',
  '/register',
  '/favicon.ico',
  '/static/.*',
  '/meetings/.*',
]
