'use client'

import React, { ButtonHTMLAttributes } from 'react'
import Loader from '@crystal-eyes/clear-components/Loaders/Loader'
import cn from 'classnames'

export type ButtonTheme = 'primary' | 'secondary' | 'only-text' | 'alternate'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode | string
  className?: string
  style?: React.CSSProperties
  loading?: boolean
  theme?: ButtonTheme
  square?: boolean
  small?: boolean
}

export default function Button({
  children,
  className,
  style,
  loading,
  theme = 'primary',
  square = false,
  small = false,
  ...props
}: ButtonProps) {
  if (theme == 'primary') {
    return (
      <button
        className={cn(
          'tw-inline-block',
          'tw-bg-tertiary',
          'tw-rounded-lg',
          'tw-text-sm',
          'tw-text-primary',
          'tw-tracking-wide',
          'tw-clear-both',
          'tw-flex',
          'tw-items-center',
          'tw-gap-2',
          'tw-font-medium',
          'tw-text-nowrap',
          'tw-shadow-xs',
          'tw-border',
          'tw-border-transparent',
          'hover:tw-border-secondary',
          'hover:tw-cursor-pointer',
          'hover:tw-no-underline',
          'active:tw-border-primary',
          'disabled:tw-cursor-not-allowed',
          'disabled:tw-border-disabled',
          'disabled:tw-bg-transparent',
          'disabled:tw-text-disabled',
          small ? 'tw-py-1.5 tw-px-4' : 'tw-py-2 tw-px-5',
          { 'tw-py-3': square },
          { 'tw-pl-3': square },
          { 'tw-pr-3': square },
          className,
        )}
        style={style}
        {...props}
      >
        {loading ? (
          <Loader circleClassName="tw-stroke-primary" size={16} />
        ) : (
          children
        )}
      </button>
    )
  }
  if (theme == 'secondary') {
    return (
      <button
        className={cn(
          'tw-inline-block',
          'tw-bg-transparent',
          'tw-rounded-lg',
          'tw-text-sm',
          'tw-text-primary',
          'tw-tracking-wide',
          'tw-clear-both',
          'tw-flex',
          'tw-items-center',
          'tw-gap-2',
          'tw-font-medium',
          'tw-text-nowrap',
          'tw-shadow-xs',
          'tw-border',
          'tw-border-secondary',
          'hover:tw-border-secondary',
          'hover:tw-cursor-pointer',
          'hover:tw-no-underline',
          'active:tw-border-primary',
          'disabled:tw-cursor-not-allowed',
          'disabled:tw-border-disabled',
          'disabled:tw-bg-transparent',
          'disabled:tw-text-disabled',
          small ? 'tw-py-1.5 tw-px-4' : 'tw-py-2 tw-px-5',
          { 'tw-py-3': square },
          { 'tw-pl-3': square },
          { 'tw-pr-3': square },
          className,
        )}
        style={style}
        {...props}
      >
        {loading ? (
          <Loader circleClassName="tw-stroke-primary" size={16} />
        ) : (
          children
        )}
      </button>
    )
  }
  if (theme == 'alternate') {
    return (
      <button
        className={cn(
          'tw-inline-block',
          'tw-bg-alternate',
          'tw-rounded-lg',
          'tw-text-sm',
          'tw-text-alternate',
          'tw-tracking-wide',
          'tw-clear-both',
          'tw-flex',
          'tw-items-center',
          'tw-gap-2',
          'tw-font-medium',
          'tw-text-nowrap',
          'tw-shadow-xs',
          'tw-border',
          'tw-border-transparent',
          small ? 'tw-py-1.5 tw-px-4' : 'tw-py-2 tw-px-5',
          'hover:tw-border-secondary',
          'hover:tw-cursor-pointer',
          'hover:tw-no-underline',
          'active:tw-border-primary',
          'disabled:tw-cursor-not-allowed',
          'disabled:tw-border-disabled',
          'disabled:tw-bg-transparent',
          'disabled:tw-text-disabled',
          { 'tw-py-3': square },
          { 'tw-pl-3': square },
          { 'tw-pr-3': square },
          className,
        )}
        style={style}
        {...props}
      >
        {loading ? (
          <Loader circleClassName="tw-stroke-primary" size={16} />
        ) : (
          children
        )}
      </button>
    )
  }
  if (theme === 'only-text') {
    return (
      <button
        className={cn(
          'tw-border-none',
          'tw-bg-transparent',
          'tw-mb-0',
          'tw-tracking-nomrmal',
          'tw-text-action',
          'tw-p-0',
          'tw-flex',
          'tw-flex-row',
          'tw-gap-1',
          'tw-text-nowrap',
          'tw-items-center',
          'hover:tw-shadow-none',
          'hover:tw-cursor-pointer',
          'disabled:tw-text-disabled',
          'disabled:tw-cursor-not-allowed',
          className,
        )}
        style={style}
        {...props}
      >
        {loading ? <Loader size={16} /> : children}
      </button>
    )
  }
}
