export type App = 'my' | 'meetings' | 'docs' | 'classic' | 'teams'
type Environment = 'local' | 'staging' | 'production'

const getEnvironment = (): Environment => {
  const appEnv = process.env.APP_ENV?.toLowerCase()

  if (appEnv === 'staging') return 'staging'
  if (appEnv === 'production') return 'production'
  return 'local'
}

const BASE_URLS = {
  classic: {
    local: 'http://localhost:3000',
    staging: 'https://staging.crystalknows.com',
    production: 'https://www.crystalknows.com',
  },
  meetings: {
    local: 'http://localhost:3003',
    staging: 'https://meetings.crystalknows.com',
    production: 'https://meetings.crystalknows.com',
  },
  docs: {
    local: 'https://docs.crystalknows.com',
    staging: 'https://docs.crystalknows.com',
    production: 'https://docs.crystalknows.com',
  },
  my: {
    local: 'http://localhost:3007',
    staging: 'https://www.crystalknows.com',
    production: 'https://my.crystalknows.com',
  },
  teams: {
    local: 'http://localhost:3004',
    staging: 'https://www.crystalknows.com',
    production: 'https://teams.crystalknows.com',
  },
}

export const getBaseUrl = (app: App = 'classic') => {
  const env = getEnvironment()
  return BASE_URLS[app][env]
}

export const routes = {
  logout: '/logout',
  settings: '/app/account/settings',
  docs: '/docs',
  dashboard: '/',
}

export const getFullUrl = (path: string, app: App = 'classic') => {
  return `${getBaseUrl(app)}${path}`
}
