'use client'
import { useRouter } from 'next/navigation'
import type { NavSection } from '../SideNavigation/types'
import cn from 'classnames'
import { App } from '@crystal-eyes/lib/routes'
interface OwnProps {
  navSections: NavSection[]
  expanded?: boolean
  appName: App
}

export default function NavTopSection({
  navSections,
  expanded,
  appName,
}: OwnProps) {
  const router = useRouter()

  return (
    <div className={cn('tw-flex', 'tw-flex-col', 'tw-gap-4')}>
      {navSections.map(section => (
        <a
          key={section.title}
          className={cn(
            'tw-flex',
            'tw-items-center',
            'tw-cursor-pointer',
            'tw-pl-4',
          )}
          onClick={() => router.push(section.path)}
        >
          <div
            className={cn(
              'tw-min-w-[40px]',
              'tw-aspect-square',
              'tw-rounded-full',
              'tw-flex',
              'tw-items-center',
              'tw-justify-center',
              {
                'tw-bg-tertiary tw-text-primary': appName !== section.appName,
                'tw-bg-alternate tw-text-alternate':
                  appName === section.appName,
              },
            )}
          >
            <section.icon className={cn('tw-w-6', 'tw-h-6')} />
          </div>
          {expanded && (
            <span
              className={cn(
                'tw-font-semibold',
                'tw-transition-opacity tw-duration-300',
                'tw-ml-3',
              )}
            >
              {section.title}
            </span>
          )}
        </a>
      ))}
    </div>
  )
}
