'use client'

import Link from 'next/link'
import { BiLogOut, BiCog, BiHelpCircle } from 'react-icons/bi'
import cn from 'classnames'
import { routes, getFullUrl } from '@crystal-eyes/lib/routes'

export default function NavBottomSection({ expanded }: { expanded: boolean }) {
  return (
    <div
      className={cn(
        'tw-flex',
        'tw-flex-col',
        'tw-gap-4',
        'tw-pb-10',
        'tw-pt-4',
      )}
    >
      <a
        href={getFullUrl('/', 'docs')}
        className={cn('tw-flex', 'tw-items-center', 'tw-pl-6')}
      >
        <BiHelpCircle className={cn('tw-w-6 tw-h-6')} />
        {expanded && <span className="tw-ml-3 tw-font-semibold">Support</span>}
      </a>
      <a
        href={getFullUrl(routes.settings, 'classic')}
        className={cn('tw-flex', 'tw-items-center', 'tw-pl-6')}
      >
        <BiCog className={cn('tw-w-6 tw-h-6')} />
        {expanded && <span className="tw-ml-3 tw-font-semibold">Settings</span>}
      </a>
      <Link
        href={routes.logout}
        className={cn('tw-flex', 'tw-items-center', 'tw-pl-6')}
        prefetch={false}
      >
        <BiLogOut className="tw-w-6 tw-h-6" />
        {expanded && <span className="tw-ml-3 tw-font-semibold">Logout</span>}
      </Link>
    </div>
  )
}
