'use client'

import useMe from '@crystal-eyes/hooks/user/useMeV2'

interface CheckFeatureAccessProps {
  featureName: string
}

export default function CheckFeatureAccess({
  featureName,
}: CheckFeatureAccessProps) {
  const { data: me } = useMe()

  const getRedirectUrl = () => {
    let url = 'https://crystalknows.com'
    if (process.env.APP_ENV === 'development') {
      url = 'http://localhost:3000'
    }
    if (process.env.APP_ENV === 'staging') {
      url = 'https://staging.crystalknows.com'
    }
    return url
  }

  if (me && !me?.features?.includes(featureName)) {
    window.location.href = getRedirectUrl()
  }
  return null
}
