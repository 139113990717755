'use client'

import { useEffect, useState } from 'react'
import DefaultImage from './DefaultImage'
import cn from 'classnames'

export type AvatarCircleProps = {
  className?: string
  style?: React.CSSProperties
  r: number
  name?: string | null
  discType?: string | null
  photoUrl?: string | null
  intensity?: number
}

export default function AvatarCircle({
  r,
  className = '',
  style = {},
  name,
  discType,
  photoUrl,
  intensity = 0,
}: AvatarCircleProps) {
  const [fallbackPhoto, setFallbackPhoto] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [preserveAspectRatio, setPreserveAspectRatio] = useState('none')
  const initials = name
    ?.split(' ')
    .map(n => n[0])
    .join('')

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    setFallbackPhoto(false)
  }, [photoUrl])

  const handleImageError = () => {
    setFallbackPhoto(true)
  }

  const handleImageLoad = () => {
    if (photoUrl && !fallbackPhoto) {
      setPreserveAspectRatio('xMidYMid slice')
    }
  }

  const radius = r
  const strokeWidth = '4'
  const viewBoxSize = r * 2 + 40
  const circumference = 2 * (22 / 7) * radius + 20
  const dashOffset = circumference - (intensity / 100) * circumference

  const getAvatar = () => {
    if (photoUrl && !fallbackPhoto) {
      return (
        <image
          className="[clip-path:inset(0_0_0_0_round_50%)]"
          href={photoUrl || ''}
          width={r * 2}
          height={r * 2}
          preserveAspectRatio={preserveAspectRatio}
          clipPathUnits="objectBoundingBox"
          onError={handleImageError}
          onLoad={handleImageLoad}
        />
      )
    } else if (initials) {
      return (
        <>
          <circle
            cx={r}
            cy={r}
            r={r}
            className={`tw-fill-disc-${discType || 'empty'}`}
          />
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            fill="white"
            dy=".3em"
            style={{ fontSize: `${r * 0.9}px` }}
          >
            {initials}
          </text>
        </>
      )
    } else {
      return <DefaultImage size={r * 2} />
    }
  }

  return (
    <div
      className={cn(
        'tw-relative tw-flex tw-flex-col tw-items-center',
        className,
      )}
    >
      {intensity > 0 && (
        <svg
          className={cn(
            'tw-transform tw--rotate-90',
            'tw-absolute',
            'tw-left-1/2',
            'tw-top-1/2',
            'tw-translate-x-[-50%]',
            'tw-translate-y-[-50%]',
          )}
          viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
          preserveAspectRatio="xMidYMid meet"
          width={viewBoxSize}
          height={viewBoxSize}
        >
          <circle
            cx={viewBoxSize / 2}
            cy={viewBoxSize / 2}
            r={viewBoxSize / 2 - 12}
            stroke="var(--color-neutral-lightest)"
            strokeWidth={strokeWidth}
            fill="transparent"
            className={cn(`tw-text-disc-${discType || 'empty'}`)}
          />

          <circle
            cx={viewBoxSize / 2}
            cy={viewBoxSize / 2}
            r={viewBoxSize / 2 - 12}
            stroke="currentColor"
            strokeWidth={strokeWidth}
            fill="transparent"
            className={cn(`tw-text-disc-${discType || 'empty'}`)}
            strokeDashoffset={dashOffset}
            strokeDasharray={circumference}
            strokeLinecap="round"
          />
        </svg>
      )}
      <svg
        className={cn('avatarSVG', className)}
        viewBox={`0 0 ${r * 2} ${r * 2}`}
        width={r * 2}
        height={r * 2}
        style={style}
      >
        {!loading && getAvatar()}
      </svg>
      {loading && <EmptyAvatar size={r * 2} />}
    </div>
  )
}

export function EmptyAvatar({ size }: { size: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      className={cn('tw-absolute tw-z-1 tw-left-0')}
    >
      <circle cx="14" cy="14" r="14" fill="#C4C4C4" />
    </svg>
  )
}
