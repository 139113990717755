'use client'

import { useEffect } from 'react'
import styles from './SideNavigation.module.css'
import NavTopSection from '@crystal-eyes/clear-components/Navigation/SideNavigation/NavTopSection'
import { usePathname } from 'next/navigation'
import { NavSection } from './types'
import NavBottomSection from './NavBottomSection'
import { BiCalendar, BiGridAlt, BiX } from 'react-icons/bi'
import classNamesBind from 'classnames/bind'
import Button from '@crystal-eyes/clear-components/Buttons/Button'
import { getFullUrl, App } from '@crystal-eyes/lib/routes'

const cn = classNamesBind.bind(styles)

interface SideNavigationProps {
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  mobileOpen: boolean
  setMobileOpen: (mobileOpen: boolean) => void
  appName: App
}

export default function SideNavigation({
  expanded,
  setExpanded,
  mobileOpen,
  setMobileOpen,
  appName,
}: SideNavigationProps) {
  const pathName = usePathname()
  const miniProfileView = !!pathName?.includes('/p/')
  const miniSettingsView = !!pathName?.includes('/settings')
  const miniView = miniProfileView || miniSettingsView

  useEffect(() => {
    if (mobileOpen) {
      setExpanded(true)
    }
  }, [mobileOpen, setExpanded])

  const navSections: NavSection[] = [
    {
      title: 'Dashboard',
      appName: 'my',
      path: getFullUrl('/', 'my'),
      icon: BiGridAlt,
    },
    {
      title: 'Meetings',
      appName: 'meetings',
      path: getFullUrl('/', 'meetings'),
      icon: BiCalendar,
    },
  ]

  return (
    <>
      {mobileOpen && (
        <div
          className="tw-fixed tw-inset-0 tw-bg-black/50 tw-z-[150] tw-transition-opacity"
          onClick={() => setMobileOpen(false)}
        >
          <Button
            onClick={() => setMobileOpen(false)}
            theme="only-text"
            className={cn('tw-absolute tw-top-4 tw-right-4')}
          >
            <BiX className={cn('tw-w-8 tw-h-8 tw-text-alternate')} />
          </Button>
        </div>
      )}
      <div
        className={cn('sideNavBar', {
          mobileOpen: mobileOpen,
          expanded: expanded,
          miniView: miniView,
        })}
        id="crystal_side_navigation"
        onMouseEnter={() => miniView && setExpanded(true)}
        onMouseLeave={() => miniView && setExpanded(false)}
        style={{ width: expanded ? '200px' : '72px', flexShrink: 0 }}
      >
        <div
          className={cn(
            'navigationContainer',
            'tw-h-full',
            'tw-flex',
            'tw-flex-col',
            'tw-items-start',
            'tw-justify-start',
            'tw-left-0',
            'tw-z-[90]',
            'tw-bg-secondary',
            {
              mobileOpen: mobileOpen,
            },
          )}
          style={{ width: '100%' }}
        >
          <div
            className={cn(
              'tw-flex',
              'tw-flex-col',
              'tw-justify-between',
              'tw-h-full',
              'tw-w-full',
              'tw-pt-4',
            )}
          >
            <NavTopSection
              appName={appName}
              navSections={navSections}
              expanded={expanded}
            />
            <NavBottomSection expanded={expanded} />
          </div>
        </div>
      </div>
    </>
  )
}
