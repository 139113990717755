interface OwnProps {
  size?: number | string
  secondary?: boolean
  style?: React.CSSProperties
  className?: string
  circleClassName?: string
}

export default function Loader({
  size = 32,
  style,
  className,
  circleClassName = 'tw-stroke-primary',
}: OwnProps) {
  return (
    <svg
      className={className && className}
      style={style}
      width={`${size}px`}
      height={`${size}px`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <rect x="0" y="0" width="100" height="100" fill="none"></rect>
      <circle
        cx="50"
        cy="50"
        r="40"
        stroke="rgba(255,255,255,0)"
        fill="none"
        strokeWidth="10"
        strokeLinecap="round"
      ></circle>
      <circle
        cx="50"
        cy="50"
        r="40"
        className={circleClassName && circleClassName}
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
      >
        <animate
          attributeName="stroke-dashoffset"
          dur="2s"
          repeatCount="indefinite"
          from="0"
          to="502"
        ></animate>
        <animate
          attributeName="stroke-dasharray"
          dur="2s"
          repeatCount="indefinite"
          values="150.6 100.4;1 250;150.6 100.4"
        ></animate>
      </circle>
    </svg>
  )
}
